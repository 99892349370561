<template>
  <div id="nav" v-if="isLoggedIn">
    <b-navbar type="dark" class="assets-nav-bar">
      <b-row>
        <b-col cols="3" sm="2" class="utel-logo-nav">
          <b-img
            left
            fluid-grow
            :src="require('../assets/utel-logo.svg')"
            alt="Logo"
          />
        </b-col>
        <b-col cols="12" sm="6" class="nav-bar-tittle d-none d-sm-block">
          <div>Administrador de sitios</div>
        </b-col>
        <b-col cols="9" sm="4">
          <b-row>
            <b-col cols="12" class="nav-logout-section">
              <div>Hola, {{ this.$store.getters.stateName }}</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="nav-logout-section">
              <b-button
                :disabled="loadingNav"
                variant="link"
                class="nav-logout-item"
                @click="logout"
              >
                <span v-if="loadingNav">
                  <b-spinner variant="dark" small></b-spinner>
                </span>
                <span v-else>
                  Salir<b-icon
                    icon="box-arrow-right"
                    style="margin-left: 5px"
                  ></b-icon>
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    loadingNav: function () {
      return this.$store.getters.isLoadingAuth;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      await this.$router.push("/");
    },
  },
};
</script>

<style scoped>
a:hover {
  cursor: pointer;
}

.assets-nav-bar {
  border-bottom: 1px solid #3c414a;
  display: block;
}

.utel-logo-nav {
  margin: auto;
  padding-right: 5%;
  padding-left: 5%;
}

.nav-logout-item {
  text-decoration: none;
  color: #7c849f;
  padding-right: 0;
  font-size: 14px;
}

.nav-bar-tittle {
  text-align: left;
  display: block;
  font-size: 24px;
  color: white;
  font-weight: bold;
  margin: auto;
}

.nav-logout-section {
  color: #ffffff;
  text-decoration: none;
  text-align: right;
  font-size: 14px;
}
</style>
