import axios from "axios";

const state = {
  images: [],
  previewImages: [],
  loading: true,
};

const getters = {
  stateImages: (state) => state.images,
  statePreviewImages: (state) => state.previewImages,
  isLoadingConf: (state) => state.loading,
};

const actions = {
  async getAvailableImages({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoadingConf", true);
    let result = await axios
      .get("manager/getAllImages/" + payload.area + "/" + payload.category, {
        headers,
      })
      .catch(() => {
        commit("setLoadingConf", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("setImages", result.status === 200 ? result.data : []);
    await commit("setLoadingConf", false);
  },
  async getPreviewImages({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoadingConf", true);
    let result = await axios
      .get(
        "/getAllImages/" +
          payload.category +
          "/" +
          payload.date,
        { headers }
      )
      .catch(() => {
        commit("setLoadingConf", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("setPreviewImages", result.status === 200 ? result.data : []);
    await commit("setLoadingConf", false);
  },
  async uploadImage({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoadingConf", true);
    let result = await axios
      .post("manager/uploadFile", payload.imageInfo, { headers })
      .catch(() => {
        commit("setLoadingConf", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });

    await commit("setNewImage", result.status === 200 ? result.data : null);
    await commit("setLoadingConf", false);
  },
  async updateImagePosition({ commit, getters }, token) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    await commit("setLoadingConf", true);
    await axios
      .post("manager/updatePositions", getters.stateImages, { headers })
      .catch(() => {
        commit("setLoadingConf", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("setLoadingConf", false);
  },
  async updateImageConf({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoadingConf", true);
    let result = await axios
      .post("manager/updateFile", payload.data, { headers })
      .catch(() => {
        commit("setLoadingConf", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("updateImage", result.status === 200 ? result.data : null);
    await commit("setLoadingConf", false);
  },
  async deleteImage({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoadingConf", true);
    await axios
      .post("manager/deleteFile", payload.data, { headers })
      .catch(() => {
        commit("setLoadingConf", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("deleteImage", payload.data);
    await commit("setLoadingConf", false);
  },
};

const mutations = {
  setImages(state, images) {
    state.images = images;
  },
  setPreviewImages(state, images) {
    state.previewImages = images;
  },
  setNewImage(state, image) {
    if (image !== null) {
      state.images.push(image);
    }
  },
  setLoadingConf(state, status) {
    state.loading = status;
  },
  updateImage(state, image) {
    if (image !== null) {
      let imageIndex = state.images.findIndex((x) => x.id === image.id);
      state.images[imageIndex].image_conf = image.image_conf;
    }
  },
  deleteImage(state, image) {
    state.images.splice(
      state.images.findIndex((x) => x.id === image.id),
      1
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
