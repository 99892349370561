import axios from "axios";
import Cookies from "js-cookie";

const state = {
  token: Cookies.get("token"),
  name: Cookies.get("name"),
  loading: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  stateToken: (state) => state.token,
  stateName: (state) => state.name,
  isLoadingAuth: (state) => state.loading,
};

const actions = {
  async LogIn({ commit }, form) {
    try{
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      await commit("setLoadingAuth", true);
      let result = await axios
          .post("/manager/auth/login", form, { headers })
          .catch((error) => {
            console.log(error.response)
            commit("setLoadingAuth", false);
          });
      await commit("setToken", result.status === 200 ? result.data.token : null);
      await commit("setUser", result.status === 200 ? result.data.name : null);
      await commit("setLoadingAuth", false);
    } catch (error){
      await commit("setLoadingAuth", false);
    }
  },
  async LogOut({ commit, getters }) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getters.stateToken}`,
    };

    await axios.post("/manager/auth/logout", [], { headers }).catch(() => {
      commit("setLoadingAuth", false);
      alert("Opps... algo ha salido mal, intentelo mas tarde");
    });

    await commit("logout");
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    Cookies.set("token", token);
  },
  setUser(state, name) {
    state.name = name;
    Cookies.set("name", name);
  },
  logout(state) {
    state.token = null;
    state.name = null;
    Cookies.remove("name");
    Cookies.remove("token");
  },
  setLoadingAuth(state, status) {
    state.loading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
