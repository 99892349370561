<template>
  <div id="app">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  components: {
    NavBar,
  },
};
</script>

<style>
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-color: #22242a;
}

/*Modal Related CSS*/

.modal-dark {
  background-color: #3c414a;
  color: white;
}

.modal-dark header {
  border: none;
}

.modal-dark footer {
  border: none;
}

.modal-dark div input {
  background-color: #292f3a;
  border-color: white;
  color: white;
}

.modal-dark div input:focus {
  background-color: #292f3a;
  border-color: white;
  color: white;
}

.modal-dark div input:disabled {
  background-color: #292f3a;
  border-color: white;
  color: white;
}

/*Dropdown Menus Css*/

.dropdown-menu-dark {
  background-color: #3c414a;
  color: white;
  max-width: 32%;
}

.dropdown-menu-dark button {
  background-color: #3c414a;
  color: white;
}

.dropdown-menu-dark button:focus {
  background-color: #3c414a;
  color: white;
}

/*Load icon*/

.loader {
  top: 50%;
  left: 50%;
  position: fixed;
}
</style>
