import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "@/views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/board",
    name: "Board",
    component: () => import("../views/Area.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:area_id/categories",
    name: "Categories",
    component: () => import("../views/Categories.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/:area_id/:category_id/manager",
    name: "Manager",
    component: () => import("../views/AssetsBoard.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/board");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
