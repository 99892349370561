import axios from "axios";

const state = {
  areas: [],
  categories: [],
  loading: false,
};

const getters = {
  stateAreas: (state) => state.areas,
  isLoading: (state) => state.loading,
  stateCategories: (state) => state.categories,
};

const actions = {
  // Area API Calls
  async getAvailableAreas({ commit }, token) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    await commit("setLoading", true);
    let result = await axios.get("manager/getAreas/", { headers }).catch(() => {
      commit("setLoading", false);
      alert("Opps... algo ha salido mal, intentelo mas tarde");
    });
    await commit("setAreas", result.status === 200 ? result.data : []);
    await commit("setLoading", false);
  },
  async createNewArea({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    let result = await axios
      .post("manager/createNewArea", payload.data, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("addArea", result.status === 200 ? result.data : []);
    await commit("setLoading", false);
  },
  async deleteArea({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    await axios
      .post("manager/deleteArea", payload.data, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("removeArea", payload.index);
    await commit("setLoading", false);
  },
  async editArea({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    await axios
      .post("manager/editArea", payload.data, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("setLoading", false);
  },
  async inviteUser({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    await axios
        .post("manager/inviteUser", payload.data, { headers })
        .catch(() => {
          commit("setLoading", false);
          alert("Opps... algo ha salido mal, intentelo mas tarde");
        });
    await commit("setLoading", false);
  },
  //----------------------------------
  // Categories Api Calls
  async getAvailableCategories({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    let result = await axios
      .get("manager/getCategories/" + payload.area, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("setCategories", result.status === 200 ? result.data : []);
    await commit("setLoading", false);
  },
  async createNewCategory({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    let result = await axios
      .post("manager/createNewCategory", payload.data, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("addCategory", result.status === 200 ? result.data : []);
    await commit("setLoading", false);
  },
  async deleteCategory({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    await axios
      .post("manager/deleteCategory", payload.data, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("removeCategory", payload.index);
    await commit("setLoading", false);
  },
  async editCategory({ commit }, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    };

    await commit("setLoading", true);
    await axios
      .post("manager/editCategory", payload.data, { headers })
      .catch(() => {
        commit("setLoading", false);
        alert("Opps... algo ha salido mal, intentelo mas tarde");
      });
    await commit("setLoading", false);
  },
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  addCategory(state, category) {
    state.categories.push(category);
  },
  removeCategory(state, index) {
    state.categories.splice(index, 1);
  },
  setAreas(state, areas) {
    state.areas = areas;
  },
  addArea(state, area) {
    state.areas.push(area);
  },
  removeArea(state, index) {
    state.areas.splice(index, 1);
  },
  setLoading(state, status) {
    state.loading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
