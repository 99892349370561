import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://site-manager-api.utel.io/api";

axios.interceptors.response.use(undefined, function (error) {
    if (error.response.status === 401) {
        store.commit("logout");
        return router.push({name: "Login"});
    }
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
