import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import assets from "./modules/assetsMng";
import assetsConf from "./modules/assetsConf";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    assets,
    assetsConf,
  },
});
