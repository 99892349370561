<template>
  <div class="login">
    <b-card class="loginForm" text-variant="white" no-body>
      <b-container>
        <b-row>
          <b-col cols="12" sm="8">
            <br />
            <div>
              <b-img
                left
                :src="require('../assets/utel-logo.svg')"
                alt="Assets Logo"
                class="utel-logo"
              ></b-img>
            </div>
            <br />
            <br />
            <br />
            <div>
              <p class="login-text-main">ADMINISTRADOR DE SITIOS</p>
              <p class="login-text-sub">
                Gestiona los sitios de los productos de innovacion
              </p>
            </div>
            <br />
            <div>
              <b-form @submit.prevent="submit">
                <b-input
                  :disabled="loading"
                  type="text"
                  placeholder="Email"
                  v-model="form.email"
                  autocomplete="off"
                  class="form-input"
                />
                <br />
                <br />
                <b-input-group v-if="showPassword">
                  <b-input
                    :disabled="loading"
                    type="text"
                    placeholder="Contraseña"
                    v-model="form.password"
                    class="form-password"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text class="form-input">
                    <b-icon
                      icon="eye-slash"
                      @click="showPassword = !showPassword"
                    ></b-icon>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group v-else>
                  <b-input
                    :disabled="loading"
                    type="password"
                    placeholder="Contraseña"
                    v-model="form.password"
                    class="form-password"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text class="form-input">
                    <b-icon
                      icon="eye"
                      @click="showPassword = !showPassword"
                    ></b-icon>
                  </b-input-group-append>
                </b-input-group>
                <br />
                <p v-if="showError" id="error">
                  Username or Password is incorrect
                </p>
                <p v-else><br /></p>
                <b-button
                  block
                  variant="primary"
                  :disabled="loading"
                  type="submit"
                  >Iniciar sesion</b-button
                >
                <br />
                <br />
              </b-form>
            </div>
          </b-col>
          <b-col cols="12" sm="4" class="d-none d-sm-block login-image">
            <!--              Placeholder for image-->
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showError: false,
      showPassword: false,
    };
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch("LogIn", this.form);
        await this.$router.push({ name: "Board" });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
  computed: {
    loading() {
      return this.$store.getters.isLoadingAuth;
    },
  },
};
</script>

<style scoped>
#error {
  color: red;
}

.login-image {
  background-image: url("~@/assets/log-in.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.loginForm {
  max-width: 95%;
  min-width: 80%;
  min-height: 50%;
  background-color: #292f3a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-input {
  background-color: #292f3a;
  border-color: white;
  color: white;
}

.form-password {
  background-color: #292f3a;
  border-color: white;
  border-right: 0;
  color: white;
}

div.input-group-text {
  background-color: #292f3a;
  border-left: 0;
  border-color: white;
  color: white;
}

.utel-logo {
  max-width: 30%;
}

.login-text-main {
  font-size: 24px;
  text-align: left;
}

.login-text-sub {
  text-align: left;
  font-size: 14px;
  color: #7c849f;
}
</style>
